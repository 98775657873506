import Header from './Header';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import ViewAllServices from './ViewAllServices';
import Footer from './Footer';

const Translation = () => {
  return (
    <Box background="linear-gradient(to left, rgba(131, 101, 142, 0.32) 10%, white 80%)">
      <Header />
      <Box>
        <Flex ml={{xl:10, lg:10, md:10, sm:8, base:8}} alignItems={'center'}>
          <Image src={require('../images/translationImgg.png')} height={20}/>
        <Text
          mb={{xl:-5}}
          sx={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)' }}
          fontWeight={800}
          fontSize={30}
          fontFamily={'abhaya-libre-extrabold'} 
          color={'#060668'}
        >
          Translation Services
        </Text>
        </Flex>
          <Box ml={{xl:10, lg:10, md:10, sm:8, base:8}} mb={10}>
            <Text fontFamily={'arvo-regular'} fontSize={20}>
              Our
              <Text as={'span'} color={'#678223'} fontWeight={800}>
                {' '}
                translation services
              </Text>{' '}
              in the EdTech sector aim to break down language barriers,
              providing a seamless and inclusive{' '}
              {/* <Text> */}
                learning experience for students worldwide. Translation Service
                These services encompass a range of activities designed{' '}
              {/* </Text> */}
              to adapt educational content for diverse linguistic and cultural
              contexts.
            </Text>
          </Box>
        <Box>
          <Text mb={8} px={10} fontFamily={'arvo-regular'} fontSize={20}>
            <Text as={'span'} fontWeight={800}  color={'#678223'} >
              E-Learning Content Translation:
            </Text>{' '}
            Translating online courses, tutorials, and educational materials to
            make them accessible to students in
            <Text>different languages</Text>
          </Text>
          <Text mb={8} px={10} fontFamily={'arvo-regular'} fontSize={20}>
            <Text as={'span'} fontWeight={800}  color={'#678223'} >
              Curriculum Translation:
            </Text>{' '}
            Translating academic curricula, textbooks, and teaching materials to
            cater to a diverse student body.
          </Text>
          <Text mb={8} px={10} fontFamily={'arvo-regular'} fontSize={20}>
            <Text as={'span'} fontWeight={800}  color={'#678223'} >
              Multilingual Support for Students:
            </Text>{' '}
            Providing translations for support materials, help guides, and
            interactive content to enhance the learning
            <Text>experience for non-native speakers.</Text>
          </Text>
          <Text px={10} fontFamily={'arvo-regular'} fontSize={20}>
            <Text as={'span'} fontWeight={800}  color={'#678223'} >
              Localization of Educational Resources:
            </Text>{' '}
            Adapting resources like video lectures, quizzes, and interactive
            modules to fit cultural and linguistic
            <Text>contexts.</Text>
          </Text>
        </Box>
        <ViewAllServices />
        <Footer />
      </Box>
    </Box>
  );
};
export default Translation;
