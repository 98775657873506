import {
    Box,
    Flex,
    Image,
    Text,
  } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
const Footer = ()=>{
  const navigate = useNavigate();
  const handleClick = (route) =>{
window.location.href = route;
  }
  const handleClickNavigate = (route)=>{
    navigate(route)
  }
  const openMap = () =>{

  }
    return(
        <Box>
            <Box
        padding={5}
        background="linear-gradient(74.59deg, #744652 52.96%, #444765 69.35%, #171D60 74.52%);"
      > 
        <Flex
          flexDirection={{ xl: 'row', md:'row', lg:'row', base: 'column' }}
          justifyContent={'space-around'}
        >
          <Box>
          <Image
          marginY={{ base: 3 }}
          width={{ xl: '88%', base: 40 }}
          height={{ xl: 95, base: 10 }}
          src={require('../images/vidyaLogo.png')}
        />
          <Box fontFamily={'reggaeone-regular'} marginTop={{md:7}} fontWeight={{xl:400, lg:300}} fontSize={{xl:24,lg:26, md:26}}>
            <Text color={'#C3C3F7'}>Streamlined Design</Text>
            <Text color={'#DAD4B5'}>Captivating Content</Text>
            <Text color={'#C8E4E3'}>Elevate Learning</Text>
          </Box>
          </Box>
          <Flex flexDirection={'column'}>
          <Box
              fontFamily={'Abhaya Libre SemiBold'}
              color={'white'}
              fontSize={{ xl: 18, lg: 16, md: 15, base: 14 }}
            >
              <Text fontFamily={'abhaya-libre-extrabold'} fontWeight={600} fontSize={{ xl: 24, lg: 20, base: 20 }} marginTop={{ md: 5, base: 5 }}>
                ABOUT US
              </Text>
              <Link to="/about#our-team"><Text cursor="pointer">Our Team</Text></Link>
              <Link to="/about#why-choose-us"><Text cursor="pointer">Why Choose us?</Text></Link>
              <Link to="/about#career"><Text cursor="pointer">Career</Text></Link>
              <Link to="/letsconnect"><Text cursor="pointer">Contact</Text></Link>
              <Link to="/about#how-we-deliver"><Text cursor="pointer">How we Deliver Things</Text></Link>
            </Box>
          <Box
            // paddingX={{ xl: 28 }}
            fontFamily={'Abhaya Libre ExtraBold'}
            // textAlign={{xl:'center'}}
            color={'white'}
          >
            <Text marginTop={{lg:10,md:10,base:5}} fontFamily={'abhaya-libre-extrabold'} fontSize={{xl:24, lg:20, md:18, base:17}} fontWeight={800}>
              GET IN TOUCH
            </Text>
            <Flex alignItems={'center'} fontSize={{xl:18, md:17, base:14}}>
              <Image
                marginRight={{xl:5, base:2}}
                height={19}
                width={30}
                src={require('../images/telephone.png')}
              />
              <Text as="span">+91731-4985066</Text>
            </Flex>
            <Flex
            fontSize={{xl:18, md:18, base:14}}
            marginRight={{xl:5}}
              justifyContent={{xl:'space-around'}}
              alignItems={'center'}
            >
              <Image
                marginRight={5}
                height={15}
                width={25}
                src={require('../images/msg.png')}
              />
              <Text as="span">info@vidyaa-tech.com</Text>
            </Flex>
          </Box>
          </Flex>
          <Flex flexDirection={'column'} alignItems={{xl:'center', lg:'center'}}>
          <Box
            fontFamily={'Abhaya Libre ExtraBold'}
            color={'white'}
            fontSize={22}
          >
            <Text marginTop={{base:5}} fontFamily={'abhaya-libre-extrabold'} fontWeight={{xl:800}} fontSize={{xl:24, base:20, md:24}}>
              OUR SERVICES
            </Text>
            <Text  fontSize={{xl:18, lg:16, md:15, base:14}}>
            <Text cursor={'pointer'} onClick={()=>handleClickNavigate('/services/editorial')}>Editorial Services</Text>
            <Text cursor={'pointer'} onClick={()=>handleClickNavigate('/services/learning')}>E-learningCustomization</Text>
            <Text cursor={'pointer'} onClick={()=>handleClickNavigate('/services/authoring')}>Authoring Services</Text>
            <Text cursor={'pointer'} onClick={()=>handleClickNavigate('/services/alignment')}>Alignment Services</Text>
            <Text cursor={'pointer'} onClick={()=>handleClickNavigate('/services/translation')}>Translation Services</Text>
            <Text cursor={'pointer'} onClick={()=>handleClickNavigate('/services/tutoring')}>Tutoring Services</Text>
            </Text>
          </Box>
          <Flex
        // marginLeft={'40%'}
        width={'100%'}
          flexDirection={{ xl: 'row',lg:'row', md:'row', base: 'column' }}
        >
          <Box
            sx={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)' }}
            fontFamily={'Abhaya Libre SemiBold'}
            fontSize={{xl:18, base:14}}
            color={'white'}
            cursor={'pointer'}
            onClick={openMap()}
          >
            <Flex
              flexDirection={{ xl: 'row' }}
              alignItems={{xl:'center'}}
              marginTop={{base:7}}
            >
              <Image
                marginLeft={{base:-3}}
                height={{xl:26, base:5}}
                width={6}
                src={require('../images/location.png')}
              />
              <Text >S-4 Agrawals, 4th floor India</Text>
            </Flex>
            <Text marginLeft={{ xl: 3 , base:3}}  >YN road, Indore - 452001</Text>
            <Text marginLeft={{ xl: 3, base:3 }} >Madhya Pradesh, India</Text>
          </Box>
        </Flex>
        </Flex>
        </Flex>
      </Box>
      <Flex
        height={50}
        padding={8}
        bg={'#464671'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box>
          <Text
           fontFamily={'abhaya-libre-extrabold'}
            fontSize={{xl:18, base:17}}
            fontWeight={{xl:600, base:300}}
            color={'white'}
          >
            ©2024 All Right Reserved By Gyanohm Learning
          </Text>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Image
            cursor={'pointer'}
            height={{xl:23, base:5}}
            src={require('../images/linkedinLogo.png')}
            mr={5}
            onClick={()=>handleClick('https://www.linkedin.com/company/vidyaatech')}
          />
           <Image
            cursor={'pointer'}
            height={{xl:23, base:5}}
            src={require('../images/instaLogo.png')}
            mr={5}
            onClick={()=>handleClick('https://www.instagram.com/vidyaatech?igsh=MXB5MXRlajBrZTdqdA%3D%3D&utm_source=qr')}
          />
           <Image
            cursor={'pointer'}
            height={{xl:23, base:5}}
            src={require('../images/fbLogo.png')}
            mr={5}
          />
        </Box>
      </Flex>
        </Box>
    )
};
export default Footer;